<template>
  <form
    class="AdminSignInForm"
    @submit="onSignInClick">
    <InputField
      name="email"
      :value="email"
      :placeholder="mixWB('ENTER_YOUR_EMAIL')"
      type="email"
      backgroundColor="white"
      @on-update="onFieldUpdate" />

    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <Button
      :text="mixWB('SEND_ME_LOGIN_LINK')"
      :isLoading="isLoading"
      @button-click="onSignInClick" />
  </form>
</template>

<script>
import Button from '@/components/Buttons/Button'
import InputField from '@/components/FormElements/InputField'
import { auth } from '@/firebase/init'

export default {
  name: 'AdminSignInForm',
  data() {
    return {
      email: '',
      errorText: '',
      isLoading: false,
    }
  },
  methods: {
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value.trim()
    },
    async onSignInClick(e) {
      if (e) {
        e.preventDefault()
      }

      if (this.isLoading) return
      this.errorText = ''

      // Validation
      if (!this.email) {
        this.errorText = this.mixWB('MISSING_EMAIL')
        return
      }

      this.isLoading = true

      // Check if email is admin
      const answer = await this.$store.dispatch('isUserAdmin', this.email)
      if (!answer) {
        this.isLoading = false
        this.errorText = this.mixWB('THIS_EMAIL_DOES_NOT_EXIST_OR_IS_NOT_AN_ADMINISTRATOR')
        return
      }

      // Create login object
      const actionCodeSettings = {
        url: `${
          window.location.origin
        }/auto-login?email=${
          this.email
        }&isAdmin=true`,
        handleCodeInApp: true,
      }

      // Send login email to user
      auth.sendSignInLinkToEmail(this.email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem('adminEmailForSignIn', this.email)

          setTimeout(() => {
            this.isLoading = false
            this.$emit('email-sent')
            this.email = ''
          }, 250)
        })
        .catch((error) => {
          this.isLoading = false
          if (error.code === 'auth/invalid-email') {
            this.errorText = this.mixWB('THE_EMAIL_YOU_ENTERED_IS_INCORRECT')
            return
          }
          this.errorText = this.mixWB('SOMETHING_WENT_WRONG_CHECK_YOUR_EMAIL_AND_TRY_AGAIN')
        })
    },
  },
  components: {
    InputField,
    Button,
  },

}
</script>

<style lang="stylus" scoped>
  .AdminSignInForm
    display block

  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem
</style>
