<template>
  <div class="Login">
    <SimpleHeader />

    <div class="LoginWrap">
      <span class="Title">{{ mixWB('ADMIN_LOGIN') }}</span>
      <div class="FormWrap">
        <AdminSignInForm @email-sent="onEmailSent" />
      </div>
    </div>

    <Dialog
      :isShowing="showEmailSentDialog"
      :useComponent="LoginEmailSentModal"
      :easyClose="false"
      @close="showEmailSentDialog = false" />
  </div>
</template>

<script>
import AdminSignInForm from '@/components/Forms/AdminSignInForm'
import Dialog from '@/components/Modals/Dialog'
import LoginEmailSentModal from '@/components/LoginEmailSentModal'
import SimpleHeader from '@/components/SimpleHeader'
import { markRaw } from 'vue'

export default {
  name: 'Login',
  data() {
    return {
      LoginEmailSentModal: markRaw(LoginEmailSentModal),
      showEmailSentDialog: false,
    }
  },
  methods: {
    onEmailSent() {
      this.showEmailSentDialog = true
    },
  },
  components: {
    Dialog,
    AdminSignInForm,
    SimpleHeader,
  },

}
</script>

<style lang="stylus" scoped>
  .Login
    display block

  .LoginWrap
    width 570px
    margin 0 auto
    padding 30px 30px 70px
    background-color rgba($color_grey_lightest, 0.8)
    span
      text-align center
      padding-bottom 20px
    .Title
      font-size 1.5rem
      font-weight bold
    .FormWrap
      margin 0 auto 40px
      width 380px
</style>
